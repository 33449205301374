var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Agreements" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "注册协议",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: ""
        },
        on: { "click-left": _vm.onClickLeft }
      }),
      _c("div", {
        staticClass: "box",
        domProps: { innerHTML: _vm._s(_vm.registerAgreement) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }